








import { defineComponent } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'

export default defineComponent({
  name: 'VHome',
  components: {},
  setup(_props, ctx) {
    return {
      ...mapGetters(ctx, ['content']),
    }
  },
})
